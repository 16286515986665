import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import TimetablePage from "../pagesLayouts/TimetablePage"
import { WordpressPageQuery } from "../types/schema"

const translation = {
  navigation: {
    left: "ZAJĘCIA",
    right: "ZESPÓŁ",
  },
}

export default function TimetablePl() {
  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "pl" } }, slug: { eq: "timetable" }) {
        ...WordpressPageFragment
      }
    }
  `
  const data = useStaticQuery<WordpressPageQuery>(query)

  return <TimetablePage data={data} translation={translation} />
}
