import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"

import Layout from "../components/Layout"
import NavigationButtons from "../components/NavigationButtons"
import Seo from "../components/Seo"
import useCommonStyles from "../library/useCommonStyles"
import { WordpressPageQuery } from "../types/schema"

type Translation = {
  navigation: {
    left: string
    right: string
  }
}

type Props = {
  data: WordpressPageQuery
  translation: Translation
}

const useStyles = makeStyles({
  gymmanagerIframe: {
    minHeight: "1550px",
  },
})

export default function TimetablePage({ data, translation }: Props) {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  return (
    <Layout>
      <Seo />
      {data?.wpPage && (
        <Container className={commonClasses.pageContainer}>
          <>
            <Typography
              component="h3"
              dangerouslySetInnerHTML={{ __html: data.wpPage.title }}
              variant="h3"
            />
            <Typography
              className={commonClasses.threeFours}
              color="secondary"
              component="div"
              dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
              variant="body1"
            />
          </>
        </Container>
      )}
      <iframe
        className={classes.gymmanagerIframe}
        frameBorder="0"
        scrolling="auto"
        src="https://fitnessmlyn.gymmanager.com.pl/reservations/index?widget=true"
        title="GymManager reservations"
        width="100%"
      />
      <NavigationButtons
        left={{
          href: "/classes",
          text: translation.navigation.left,
        }}
        right={{
          href: "/team",
          text: translation.navigation.right,
        }}
      />
    </Layout>
  )
}
